import React from 'react';
import styles from './Form.module.scss';
import Emizavrik from '../../images/emizavr2.svg';
import FormOnly from '../../Components/FormOnly/FormOnly';
import oblozhka from '../../images/oblozhka.jpg';
import izdatel from '../../images/knigochet.svg';
import labirint from '../../images/labirint.png';
import ozon from '../../images/ozon.png';

function Form() {
    return(
        <section id="section6" className={styles.form}>
                
                <div className={styles.form__content}>

                    <div className={styles.form__main_container}>
                        <img src={Emizavrik} alt='Эмизаврик' className={styles.form__emizavrik} />
                        <p className={styles.form__mainText}>
                        -Пора английским заниматься, <br/>
                        Но как нам в этом разобраться? <br/>
                        -Все просто, друзья, не спешите грустить. <br/>
                        Советуем вам нашу книгу купить! <br/>
                        А чтоб без посредников взять (напрямую), <br/>
                        Заполните рядом форму простую!<br/>
                        </p>
                        <div className={styles.form__square}>
                            <FormOnly />
                        </div>
                    </div>
                    <div className={styles.form__text_container}>
                        <div className={styles.form__oblozhka_container}>
                            <p className={styles.form__text}>
                            Если хочешь узнать секреты <br/>
                            И проверить свои ответы, <br/>
                            Методичку у нас закажи- <br/>
                            Постарались мы там от души! <br/>
                            </p>
                            <div className={styles.form__oblozhka}>
                                <img src={oblozhka} alt='обложка' className={styles.form__obl}></img>
                            </div>
                        </div>              
                        <div className={styles.form__link_container}>
                            <p className={styles.form__text}>
                                Не хочешь ты данные нам оставлять, <br/>
                                У издателя книгу спеши заказать! <br/>
                                Там есть промо-код. Ошибиться нельзя! <br/>
                                30% по слову "Друзья". <br/>
                            </p>
                            <div className={styles.form__oblozhka}>
                            <a className={styles.form__izdatel} href='https://xn----etbhjjsj8a5b.xn--p1ai/info/avtory/emelina-d-s-loginova-i-a/'>
                                <img src={izdatel} alt='издатель' className={styles.form__link}></img>
                               </a>
                            </div>
                        </div>
                        <div className={styles.form__link_container}>
                            <p className={styles.form__text}>
                            Ни то, ни другое не мило тебе? <br/>
                            Эмизаврик поможет и в этой беде. <br/>
                            Все уже знают, какой добрый он - <br/>
                            Скорей заходи в Лабиринт и Озон. <br/>
                            </p>
                            <div className={styles.form__marketplaces}>
                            <a href='https://www.labirint.ru/books/974467/'>
                                <img src={labirint} alt='Магазин Лабиринт' className={styles.form__link}></img>
                            </a>
                            <a href='https://ozon.ru/t/YXryVLj'>
                                <img src={ozon} alt='Магазин Озон' className={styles.form__link}></img>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
    )
}

export default Form;