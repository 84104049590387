import React from 'react';
import styles from './Reasons.module.scss';
import ellipseZel from '../../images/ellipseZel.png';
import EllipseDarkG from '../../images/EllipseDarkG.png';


function Reasons() {
    return (
        <section  className={styles.reasons}>
            <h2 className={styles.reasons__title}>Три причины</h2>
            <p className={styles.reasons__subtitle}>Чтобы начать....</p>
            <div className={styles.reasons__wrapper}>
                <img src={ellipseZel} alt='зеленый элипс' className={styles.reasons__ellipse1}/>
                <img src={EllipseDarkG} alt='зеленый элипс' className={styles.reasons__ellipse2}/>
                <img src={ellipseZel} alt='зеленый элипс' className={styles.reasons__ellipse3}/>
                <div className={styles.reasons__content}>
                    <div className={styles.reasons__item}>
                        <h3 className={styles.reasons__desc}>1. Лексика</h3>
                        <p className={styles.reasons__text}>БОльшая часть лексики представлена в стихотворной форме с использованием технологии «договорок», которые близки детям младшего школьного возраста.</p>
                    </div>
                    <div className={styles.reasons__item}>
                        <h3 className={styles.reasons__desc2}>2. Чтение</h3>
                        <p className={styles.reasons__text}>Чтение буквосочетаний обыгрывается историей или подкрепляется зрительной ассоциацией, так как у детей младшего школьного возраста преобладает наглядно-образное мышление.</p>
                    </div>
                    <div className={styles.reasons__item}>
                        <h3 className={styles.reasons__desc}>3. Грамматика</h3>
                        <p className={styles.reasons__text}>Изучение правил дается в стихотворной форме, что облегчает работу с грамматикой английского языка. Изучение идет быстро и очень легко, а главное - весело.</p>
                    </div>
                </div>
            </div>
            <p className={styles.reasons__final}>Наберись, дружок, терпения!</p>
            <p className={styles.reasons__final2}>И в радость будет изучение!</p>
        </section>
       
    )
}

export default Reasons;

