import React from 'react';
import styles from './MainIntro.module.scss';
import picture from '../../images/picture.png'


function MainIntro() {
    return (
        <section id="section1" className={styles.intro}>
            <div className={styles.intro__container}>
                <h1 className={styles.intro__title}>Эмизаврик и его друзья</h1>
                <p className={styles.intro__text}>Учим английский легко и увлекательно</p>
                <div className={styles.intro__wrap}>
                    <a className={styles.intro__button} href="#section2">Подробнее</a>
                </div>
            </div>
                <img className={styles.intro__image} src={picture} alt=""/>
        </section>
    
    )
}

export default MainIntro;