import React from 'react';
import styles from './Authors.module.scss';
import girl from '../../images/devochka2.png';
import Daria from '../../images/Daria.png';
import Irina from '../../images/Irina.png';

function Authors(){
    return(
        <section id="section3" className={styles.authors}>
            <h2 className={styles.authors__title1}>Об авторах</h2>
            <div className={styles.authors__content}>
                <img src={girl} alt='девочка' className={styles.authors__girl}/>
                <div className={styles.authors__person}>
                    <div className={styles.authors__title}>
                        <img src={Daria} alt='Дарья' className={styles.authors__img} />
                        <p className={styles.authors__name}>Емелина Дарья Сергеевна</p>
                    </div>
                    <p className={styles.authors__text}> 
                    Является учителем высшей квалификационной категории.
                    Опыт работы в школе 11 лет.
                    Окончила ФГБОУ ВПО МПГУ.
                    Стажировка в Англии Wolsingham School.
                    Стажировка в Англии Silver Tree Primary School, development of the IB PYP.
                    Курсы IB PYP и ФГОС с 2014 по 2020.</p>
                </div>
                <div className={styles.authors__person}>
                    <div className={styles.authors__title}>
                    <img src={Irina} alt='Ирина' className={styles.authors__img} />
                    <p className={styles.authors__name}>Логинова Ирина Александровна</p>
                    </div>
                    <p className={styles.authors__text}>
                    Является учителем высшей квалификационной категории. Опыт работы в школе 15 лет.
                    Окончила ФГБОУ ВПО МПГУ.
                    Организация и участие в культурных обменах на базе Европейского студенческого форума AEGEE.
                    Стажировка в Англии Silver Tree Primary School, development of the IB PYP.
                    Курсы IB PYP и ФГОС с 2014 по 2020.
                    </p>
                </div>
            </div>
            <p className={styles.authors__speech}>
            Мы работаем в школе более 10 лет, и на практике столкнулись с проблемой обучения английскому языку на первой ступени. Пособий много – толку мало. В одних источниках – лексика, в других - упор на письмо, в следующих - грамматика, а в каких-то - устная речь. Таким образом, в них нет комплексного подхода, поэтому мы сами собирали «с миру по нитке», выстраивая свою систему работы. 
            </p>
        </section>
       
    )
}

export default Authors