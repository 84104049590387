import React from 'react';
import styles from './FormOnly.module.scss';
import {Link} from "react-router-dom";

function FormOnly() {
    return(
        <form id='form' className={styles.formOnly}>
            <h3 className={styles.formOnly__title}>Мы ждем вас среди друзей</h3>
            <input className={styles.formOnly__input} required type="text" placeholder="Ваше Имя"
                />
            <input className={styles.formOnly__input} required type="tel" placeholder="Телефон"
                />
            <input className={styles.formOnly__input} required type="email" placeholder="E-mail"
                />
            <div className={styles.formOnly__container_check}>
                <input className={styles.formOnly__checkbox} type="checkbox"/>
                <p className={styles.formOnly__link}> Я согласен с <Link
                    className={styles.formOnly__link_accent} target="_blank" to='/policy'>политикой обработки
                    данных</Link></p>
            </div>
            <button            
            className={styles.formOnly__button} 
            type="submit"
            >Отправить заявку</button>
        </form >
    )
}

export default FormOnly;