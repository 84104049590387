import React from 'react';
import styles from './MainAbout.module.scss';
import krugZelen from '../../images/krug-zelen.png';
import krugSer from '../../images/darkgreen.svg';
import ellipseZel from '../../images/ellipseZel.png';
import EllipseDarkG from '../../images/EllipseDarkG.png';
import girl from '../../images/devochka.png';
import boy from '../../images/malchik.png';

function MainAbout() {
    return (
        <section id="section2" className={styles.about}>
            <h2 className={styles.about__title}>О книге</h2>
            <div className={styles.about__content}>
              <img src={girl} alt='девочка' className={styles.about__girl}/>
              <div className={styles.about__textZone}>
                <p className={styles.about__firstP}>
                <img src={krugZelen} alt='зеленый круг' className={styles.about__krugLittle}/>
                Данное учебное пособие является частью учебно-методического комплекта «Эмизаврик и его друзья». 
                Книга предназначена для детей младшего школьного возраста, которые делают свои первые шаги в изучении английского языка. Данное пособие позволяет сформировать элементарную коммуникативную компетенцию по всем видам речевой деятельности: говорение, аудирование, чтение, письмо. 
                </p>
                <img src={ellipseZel} alt='зеленый элипс' className={styles.about__ellipse1}/>
                <p className={styles.about__secondP}>
                <img src={krugSer} alt='серый круг' className={styles.about__krugLittle}/>
                Достоинством книги является поступательное изучение основ английского языка с игровыми и творческими элементами. 
                В данном пособии отражен инновационный подход при изучении алфавита и обучении чтению через формирование зрительной ассоциации. 
                Большая часть лексики и грамматики представлена в стихотворной форме. Главный герой книги &#40;Эмизаврик&#41; знакомит детей с основами грамматического строя английского языка. 
                В конце учебного пособия находится "Словарик Эмизаврика", где собраны все лексические единицы, встречающиеся в уроках.
                </p>
                <img src={EllipseDarkG} alt='серый элипс' className={styles.about__ellipse2}/>
               </div>
               <img src={boy} alt='мальчик' className={styles.about__boy}/>
            </div>
        </section>
    
    )
}

export default MainAbout;