import React from 'react';
import styles from './Video.module.scss';
import poster from '../../images/poster.png'
import ellipseZel from '../../images/ellipseZel.png';
import ellipseDark from '../../images/EllipseDarkG.png';
import oblozhka from '../../images/oblozhka.svg';
import devochka from '../../images/devochka10.svg';
import { useState, useRef } from 'react';
import video from '../../images/video.mp4';

function VideoBlock(){

  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleOverlayClick = () => {
    handlePlayPause(false);
  };

    return (
        <section className={styles.video}>
            <h3 className={styles.video__title}>Первые уроки, первые знания!</h3>
            <div className={styles.video__tablet}>
                <div className={styles.video__podlozhka}>
                    <img  src={oblozhka} alt='обложка' className={styles.video__oblozhka}/>
                </div>
                <img  src={devochka} alt='девочка' className={styles.video__dev}/>
            </div>
            <div className={styles.video__content}>
            <img src={ellipseZel} alt='зеленый элипс' className={styles.video__ellipse1}/>
                <video
                    ref={videoRef}
                    alt="Картинка, затем видео"
                    className={styles.video__play}
                    src={video}
                    onClick={handleOverlayClick}
                    poster={poster}
                    playsInline
                />
            <img src={ellipseDark} alt='темно-зел элипс' className={styles.video__ellipse2}/>
          <button
            onClick={handlePlayPause}
            type="button"
            className={isPlaying ? styles.video__button_invisible : styles.video__button}></button>
        </div>
            <h3 className={styles.video__title}>Успехов тебе в твоих начинаниях!</h3>
        </section>
       
    )
}

export default VideoBlock;