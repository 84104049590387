import React from 'react';
import styles from './Footer.module.scss';

function Footer(){
    return(
            <footer className={styles.footer}>
                <p className={styles.footer__text}>
                    All Rights Reserved. Сайт разработан командой <span className={styles.footer__span}>Black Sun Studio.</span> 2023
                </p>
            </footer>
    )
}

export default Footer;