import React from 'react';
import styles from './Contacts.module.scss';
import girl from '../../images/devochka4.svg';
import boy from '../../images/malchik4.svg';
//import poster2 from '../../images/poster2.png';
import telega from '../../images/telega.svg';
import youtube from '../../images/youtube.svg';
import ellipseZel from '../../images/ellipseZel.png';
import { useState, useRef } from 'react';
import video from '../../images/vide02c.mp4';


function Contacts(){


    const video2Ref = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
  
    const handlePlayPause = () => {
      if (isPlaying) {
        video2Ref.current.pause();
      } else {
        video2Ref.current.play();
      }
      setIsPlaying(!isPlaying);
    };
  
    const handleOverlayClick = () => {
      handlePlayPause(false);
    };


    return(
        <section id="section5" className={styles.contacts}>
            <h2 className={styles.contacts__title}>Контакты</h2>     
            <div className={styles.contacts__wrapper}>
                <img src={girl} alt='девочка' className={styles.contacts__girl}/>
                <div className={styles.contacts__content}>
                <img src={ellipseZel} alt='зеленый элипс' className={styles.contacts__ellipse1}/>
                    <video
                        ref={video2Ref}
                        alt="Картинка, затем видео"
                        src={video}
                        className={styles.contacts__play}
                        onClick={handleOverlayClick}
                        playsInline
                    />
                     <button
                        onClick={handlePlayPause}
                        type="button"
                        className={isPlaying ? styles.contacts__button_invisible : styles.contacts__button}></button>
                    <div className={styles.contacts__container}>
                        <div className={styles.contacts__info}>
                            <p className={styles.contacts__contacttext}>Москва</p>
                            <a href='tel:+79031004722' className={styles.contacts__contacttext}>+7 903 100 47 22</a>
                            <a href='tel:+79266511363' className={styles.contacts__contacttext}>+7 926 651 13 63</a>
                            <a href="mailto:amisarevrik@mail.ru" className={styles.contacts__contacttext}>amisarevrik@mail.ru</a>
                        </div>
                        <div className={styles.contacts__sites}>
                        <a href='https://youtube.com/@amisarevrik?si=E1BK6StdhhSfdbGd' rel="noopener noreferrer" target="_blank" className={styles.contacts__link}>
                            <img src={youtube} alt='телега' className={styles.contacts__site}/>
                        </a>
                        <a href='https://t.me/+VR7celS17KI1Zjky'  rel="noopener noreferrer" target="_blank" className={styles.contacts__link}>
                            <img src={telega} alt='телега' className={styles.contacts__site}/>
                        </a>
                        </div>
                    </div>
                </div>
                <img src={boy} alt='мальчик' className={styles.contacts__boy}/>
            </div>      
        </section>
    )
}

export default Contacts