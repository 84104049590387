//import styles from './Main.module.scss'
import React from 'react';
import MainIntro from '../../blocks/MainIntro/MainIntro';
import MainAbout from '../../blocks/MainAbout/MainAbout';
import VideoBlock from '../../blocks/Video/Video';
import Reasons from '../../blocks/Reasons/Reasons';
import Authors from '../../blocks/Authors/Authors';
import Faq from '../../blocks/FAQ/Faq';
import Contacts from '../../blocks/Contacts/Contacts';
import Form from '../../blocks/Form/Form';
import Reviews from '../../blocks/Reviews/Reviews';
import HelmetComponent from "../../Components/Helmet/Helmet";


function Main() {
    return (
        <>
        <HelmetComponent
                title={'Amisarevrik'}
                description='Amisarevrik'
            />
        <MainIntro />
        <MainAbout />
        <VideoBlock />
        <Reasons />
        <Authors />
        <Reviews />
        <Form />
        <Faq />
        <Contacts />
        </>
    )
}

export default Main;