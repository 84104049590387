import React from "react";
import styles from './Element404.module.scss';
import { Link } from 'react-router-dom';
import HelmetComponent from "../../Components/Helmet/Helmet";


function Element404(){
    return(
        <>
        <HelmetComponent
                    title='404'
                    description='404'
        />
        <div className={styles.error404}>
            <h1 className={styles.error404__title}>404</h1>
            <div className={styles.error404__container}>
                <h2 className={styles.error404__subtitle}>Упс,что-то пошло не так...</h2>
                <div className={styles.error404__wrap}>
                        <Link className={styles.error404__button} to={'/'}>На главную</Link>
                </div>
            </div>           
        </div>
        </>
    )
}

export default Element404;
