import React from 'react';
import styles from './Reviews.module.scss';
import EllipseDarkG from '../../images/EllipseDarkG.png';
import ellipseZel from '../../images/ellipseZel.png';
import photo1 from '../../images/girl4.svg';
import photo2 from '../../images/boy4.svg';

function Reviews() {
    return (
        <section id="section4" className={styles.reviews}>
            <h2 className={styles.reviews__title}>Отзывы</h2>
            <div className={styles.reviews__content}>
            <img src={EllipseDarkG} alt='зеленый элипс' className={styles.reviews__ellipse2}/>
            <img src={EllipseDarkG} alt='зеленый элипс' className={styles.reviews__ellipse1}/>
            <img src={ellipseZel} alt='зеленый элипс' className={styles.reviews__ellipse3} />
                <div className={styles.reviews__wrapper}>
                        <div className={styles.reviews__person}>
                            <img src={photo1} alt='фото девочки' className={styles.reviews__girl}/>
                            <div className={styles.reviews__box}>
                                <p className={styles.reviews__name}>Батова Нонна,</p>
                                <p className={styles.reviews__name_span}> учитель английского языка, педагог высшей категории.</p> 
                            </div>
                        </div>
                            <p className={styles.reviews__text}> 
                            Издание адаптирует изучение английского для младших школьников в соответствии с их типом восприятия, делая обучение увлекательным и при этом максимально эффективным. Методы, используемые авторами, были многократно опробованы и прошли успешные «испытания» во время работы с различными группами учеников младших классов.</p>
                </div>
                <div className={styles.reviews__wrapper}>
                        <div className={styles.reviews__person}>
                            <img src={photo1} alt='фото девочки' className={styles.reviews__girl}/>
                            <div className={styles.reviews__box}>
                                <p className={styles.reviews__name}>Мисайлова Юлия,</p>
                                <p className={styles.reviews__name_span}>журналист, участник поэтический сборников.</p> 
                            </div>
                        </div>
                            <p className={styles.reviews__text}>
                            Учебное пособие «Эмизаврик и его друзья» – это настоящий «сундук с сокровищами» и для школьников, начинающих свой путь в изучении английского языка, и для их преподавателей. Информация представлена занимательно и в доступной форме, структурирована и подается авторами «порционно», по принципу «снежного кома»: новые данные подкрепляются повторением уже пройденного.
                            </p>
                </div>
                <div className={styles.reviews__wrapper}>
                        <div className={styles.reviews__person}>
                            <img src={photo2} alt='фото мальчика' className={styles.reviews__girl}/>
                            <div className={styles.reviews__box}>
                                <p className={styles.reviews__name}>Медведев Пётр,</p>
                                <p className={styles.reviews__name_span}>лингвист, педагог дополнительного образования.</p>
                            </div>                            
                        </div>
                            <p className={styles.reviews__text}>
                            Учебное пособие «Эмизаврик и его друзья» - яркий пример продуктивного учебного ресурса для обучения английскому языку в начальной школе. Для меня, как учителя, главное в этом учебнике - его система. Она позволяет постепенно и последовательно расширять знания и умения учащихся. При этом материал подается в привлекательной для маленьких учеников форме и не выглядит перегруженным.
                            </p>
                </div>
            </div>
            <p className={styles.reviews__speech}>Здесь встретишь героев ты разных! И много заданий прекрасных!</p>
        </section>
    )
}

export default Reviews;