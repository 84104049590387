import styles from './App.module.scss'
import Main from "../../Pages/Main/Main";
import Element404 from '../../Pages/404/Element404';
import Policy from '../Policy/Policy';
import {Route, Routes} from "react-router-dom";
import Layout from "../Layout/Layout";

function App() {
  return (
      <div className={styles.page}>
           <Routes>
                    <Route path="/" element={<Layout/>}>
                        <Route index element={<Main/>} />
                        <Route path="*" element={<Element404/>} />
                        <Route path="/policy" element={<Policy />} />
                    </Route>
            </Routes>
      </div>
  );
}

export default App;