import React from 'react';
import styles from './Faq.module.scss';
//import line from '../../images/line.svg';

function Faq(){
    return (
        <section className={styles.faq}>
            <h2 className={styles.faq__title}>Частые вопросы</h2>
            <div className={styles.faq__content}>
               
                <div className={styles.faq__paragraph}>
                    <p className={styles.faq__question}>Для кого эта книга?</p>
                    <p className={styles.faq__answer}>
                    Учебник подходит для большого круга людей. Это не только учителя, но также репетиторы, кружководы, руководители секций, мамы и бабушки. Издание доступно такой широкой аудитории, так как к данному пособию прилагается книга для учителя, в которой подробно описаны все действия по каждому занятию. Даже если вы не знаете или не помните английский язык, то у вас есть прекрасная возможность начать все с начала.
                    </p>
                </div>
                
                <div className={styles.faq__paragraph}>
                    <p className={styles.faq__question}>Какова структура курса?</p>
                    <p className={styles.faq__answer}>
                    Книга содержит: 62 урока; вводный курс; знакомство со звуками и буквами английского алфавита; основы чтения; особые случаи чтения; знакомство с грамматикой; резервные уроки; дополнительные материалы и много чего еще:&#41;
                    </p>
                </div>
               
                <div className={styles.faq__paragraph}>
                    <p className={styles.faq__question}>Есть ли аудиокурс?</p>
                    <p className={styles.faq__answer}>
                    Уже на нулевом уроке дети встречаются с аудиозаписями, что способствует формированию навыков аудирования, то есть понимания на слух. Короткие стишки и песенки помогают лучше усвоить изученный материал. Мы предлагаем изучать их, используя прием «пантомима», потому что детям необходима двигательная активность. Все аудиозаписи можно найти в нашем Telegram-канале.
                    </p>
                </div>
                
                <div className={styles.faq__paragraph}>
                    <p className={styles.faq__question}>Как изучается алфавит?</p>
                    <p className={styles.faq__answer}>
                    Один из разделов книги посвящен звукам и буквам английского алфавита. Мы предлагаем авторский подход к порядку изучения букв и звуков. Так, мы разделили алфавит на 6 блоков: гласные, затем - согласные, которые разделены на 4 подгруппы, и отдельно - буква Yy. Также в этом разделе дети начинают потихоньку учиться писать.
                    </p>
                </div>
                
            </div>
        </section>
    )
}

export default Faq