import styles from './Header.module.scss'
import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import logo from "../../images/mainemi.svg";
import phone from '../../images/phone.svg';
import picture from '../../images/ImageMobile.svg';

function Header() {
    const [open, setOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isOpened, setIsOpened] = useState(false);

    useEffect(() => {
        setIsOpened(false)
    }, [])
    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const closeMenu = () => {
        if (windowWidth <= 980) {
            document.body.style.overflow = !open ? 'hidden' : ''
            setOpen(!open)
        }
    }

    return (

        <header className={styles.header}>
            <div className={styles.layout}>
            {windowWidth <= 980 && !isOpened
                ?
                <button onClick={closeMenu}
                className={open ? `${styles.header__close} ${styles.header__close__open}` : styles.header__close}></button>
                : ''}

                <Link to='/'>

                    <img src={logo} className={styles.layout__logotip} alt="Логотип сервиса emizavrik"/>

                </Link>

                <nav className={open ? `${styles.header__nav} ${styles.header__nav_open}` : styles.header__nav}>

                    <a
                        onClick={closeMenu}
                        className={styles.header__navItem} 
                        href="#section1">Главная
                    </a>
                    <a
                        onClick={closeMenu}
                        className={styles.header__navItem} 
                        href="#section2">О книге
                    </a>
                    <a
                        onClick={closeMenu}
                        className={styles.header__navItem} 
                        href="#section3">Об авторах
                    </a>
                    <a
                        onClick={closeMenu}
                        className={styles.header__navItem}
                        href="#section4">Отзывы
                    </a>
                    <a
                        onClick={closeMenu}
                        className={styles.header__navItem}     
                        href="#section5">Контакты
                    </a>
                    <img className={styles.header__menuImage} src={picture} alt=""/>
                </nav>

                <div className={styles.club}>
                    <a href="#section6">
                        <button className={styles.club__button}>
                            Вступить в клуб
                        </button> 
                    </a>
                    <a href='tel:+79031004722' className={styles.club__phone}>
                        <img src={phone}  alt="phone"/>
                    </a>
                       
                </div>
            </div>
        </header>

    );

}


export default Header;